
/**
 * Return a function that will reload app on redirect
 * @deprecated use useRedirectHard instead
 */
export const useHardRedirect = () => {
    return (url: string, targetBlank?: boolean) => {
        if (targetBlank) {
            window.open(url, '_blank');
            return;
        }
        window.location.href = url
    }
}
