import { AppConfig, TContract, TIdentity } from "../models";
import { HttpService, Logger } from "../utils";
import { BaseService } from "./BaseService";

export class ContactService extends BaseService {

    constructor(params: AppConfig){
        super(params)
    }

    async getContractData(): Promise<TContract | null> {
        try {
            const result = await HttpService.get(
                `${this.controllerUrl}/elyx/GetContractData`,
                {}
            );
            return result.data;
        }
        catch (err) {
            Logger.error(err, { method: this.getContractData.name });
            throw err;
        }
    }
}
