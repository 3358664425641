import { AppConfig } from "../models/Config";

export class BaseService {

    config: AppConfig

    protected controllerUrl: string

    constructor(params: AppConfig) {
        this.config = params
        this.controllerUrl = params.apiUrl
    }

}
