import { EphattaRouteProps } from "components/root/Shell"
import React from "react"

const Home = React.lazy(() => import('./Home'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/',
        element: <Home />,
    }
]

export default Routes
