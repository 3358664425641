import Routes from './pages/Routes'
import { Theme } from './config/Theme'
import { Root } from 'components/root'

const App = () => {
    
    return (
        <Root 
            theme={Theme}
            loginPath="/login"
            initialPath="/home"
            routes={Routes}
            loggedRoutes={loggedRoutes}
            loggedOutRoutes={loggedOutRoutes}
            loggedUnAuthorizedRootes={loggedUnAuthorizedRootes} />
    )
}

const loggedRoutes = '*'

const loggedOutRoutes = [
    '/login',
    '/forgot-password',
    '/establishment/proximity',
    '/register'
]

const loggedUnAuthorizedRootes = [
    '/login',
    '/forgot-password'
]

export default App;
