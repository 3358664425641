import {
    createTheme,
    ThemeProvider,
    Theme,
    ThemeOptions
} from '@mui/material';
import React, { Suspense } from 'react'
import { QueryClient, QueryClientProvider } from "react-query"
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot, RecoilRootProps } from "recoil"
import { Shell, ShellProps } from './Shell'
import './i18n'
import { MainLoader } from 'components/progress/MainLoader';
import { config } from 'config/Config';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


export const Root = (props: Props) => {

    const queryClient = new QueryClient({
        defaultOptions: config.query,
    })

    return (
        <RecoilRoot {...props}>
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ThemeProvider theme={createTheme({...props.theme })}>
                        <Suspense fallback={<MainLoader />}>
                            <BrowserRouter>
                                <Shell {...props} />
                            </BrowserRouter>
                        </Suspense>
                    </ThemeProvider>
                </LocalizationProvider>
            </QueryClientProvider>
        </RecoilRoot>
    );
}

type Props = RecoilRootProps 
    & ShellProps 
    & {
        theme?: ThemeOptions
    }
