import { useDisplayAlert } from "hooks/alert";
import { useCallback, useEffect } from "react";
import { QueryKey, UseMutationResult, UseQueryResult } from "react-query";
import { SnackbarItem } from "../../components/snackbar/SnackbarList";
import { useDebounce } from "./useDebounce";
import { useReloadPage } from "./useReloadPage";

/**
 * Hooks to monitor query
 * If there's error, it will show a snackbar error on the Layout
 * If there's success, it will show a snackbar success on the layout
 * These messages are customizable usign config parameter
 */
export const useMonitorQuery = 
    <TData, TError = any>(query: UseQueryResult<TData, TError> | UseMutationResult<TData, TError>,
                    config?: UseMonitorQueryConfig<TData, TError>,
                    queryKey?: QueryKey) => {

    const displayAlert = useDisplayAlert()
    const reload = useReloadPage()
    const debounce = useDebounce()
    
    useEffect(() => {

        if (shouldReload(query.error)) {
            displayAlert(getSessionExpiredAlert())
            return debounce(() => reload(), 5000)
        }

        if (query.isError && (config?.errorMessage || config?.transformErrorMessage)) {
            displayAlert(getErrorAlert())
            console.error(`Query Error`, query.error)
        }
    }, [query.isError, query.error])

    useEffect(() => {
        if (query.isSuccess && config?.successMessage) {
            displayAlert(getSuccessAlert())
        }
    }, [query.isSuccess])

    const shouldReload = useCallback((error: any) => {

        // let errorData: any = {}
        // if (error?.response) {
        //     errorData = error?.response
        // }

        // if (error?.response?.data) {
        //     errorData = error?.response?.data
        // }

        // if (error?.response?.error && typeof error?.response?.error === 'string') {
        //     errorData = JSON.parse(error?.response.error)
        // }

        return false // Todo : with what reason should we reload the app
    }, [query.error, query.isError])
    
    const getErrorAlert = useCallback((): SnackbarItem => {
        return {
            id: JSON.stringify(queryKey),
            label: getErrorAlertLabel(),
            autoHideDuration: 10000
        }
    }, [query.error, config])

    const getSessionExpiredAlert = useCallback((): SnackbarItem => {
        return {
            id: JSON.stringify(queryKey),
            label: 'La session a expiré',
            autoHideDuration: 5000,
        }
    }, [query.error, config])

    const getSuccessAlert = useCallback((): SnackbarItem => {
        return {
            id: JSON.stringify(queryKey),
            label:  getSuccessAlertLabel(),
            autoHideDuration: 5000,
            action: config?.onSuccessActionClick && {
                label: config.successActionLabel || 'Fermer',
                onClick: () => config?.onSuccessActionClick?.()
            },
            AlertProps: {
                severity: 'success',
            }
        }
    }, [query.error, config])
    
    const getErrorAlertLabel = useCallback(() => {
        if (config?.transformErrorMessage) {
            return config?.transformErrorMessage?.(query.error || undefined)
        }
            
        if (config?.errorMessage) {
            return config?.errorMessage
        }
        
        return 'Une erreur s\'est produite'
    }, [config, query.error])

    const getSuccessAlertLabel = useCallback(() => {
        if (config?.transformSuccessMessage) {
            return config?.transformSuccessMessage?.(query.data)
        }

        if (config?.successMessage) {
            return config?.successMessage
        }
        
        return 'Opération terminé avec succès'
    }, [config, query.data])


}

export type UseMonitorQueryConfig<TData, TError = any> = 
    QueryErrorType<TError>
    & QuerySuccessType<TData>


export type QueryErrorType<TError> = {
    /** Display an alert when error occured. It will invite the user to retry. */
    errorMessage?: string
    /** Action button label when error occured */
    errorActionLabel?: string
    /** 
     * Callback when user clicked on action button
     * If undefined, it hide the action button
     */
    onErrorActionClick?: () => void
    /** 
     * Callback that will try to transform error message to string 
     * Return undefined will fallback to generic message
     */
    transformErrorMessage?: (error?: TError) => string | undefined
    /** Flag telling to show error message when an error is occured */
    hideErrorMessage?: boolean
}

export type QuerySuccessType<TData> = {
    /** Display a success alert for a few seconds */
    successMessage?: string
    /** Action button label when error occured */
    successActionLabel?: string
    /** 
     * Callback when user clicked on action button
     * If undefined, it hide the action button
     */
    onSuccessActionClick?: () => void
    /** 
     * Callback that will try to transform error message to string
     * Return undefined will fallback to generic message
     */
    transformSuccessMessage?: (data?: TData) => string | undefined
}