import React from "react"
import { useRecoilState } from "recoil"
import { RedirectionState } from "states/RedirectionState"

export const Redirection = () => {

    const [redirection, setRedirection] = useRecoilState(RedirectionState)

    const handleRef = (_ref: HTMLFormElement | null) => {
        _ref?.submit()
        redirection !== null && setRedirection(null)
    }

    if (!redirection) {
        return (
            <React.Fragment></React.Fragment>
        )
    }

    return (
        <form 
            action={redirection.url} 
            method="POST" 
            target={redirection.isTargetBlank ? '_blank' : ''}
            ref={handleRef}>
            {
                redirection && Object.keys(redirection.data).map((key, index) => {
                    const value = redirection.data[key]
                    return (
                        <input type="hidden" name={key} value={value} />
                    )

                })
            }
        </form>
    )
}
