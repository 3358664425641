import { EphattaRouteProps } from 'components/root/Shell'
import { MainLayout } from '../components/layout/MainLayout'
import HomeRoutes from './home/Routes'

const Routes: EphattaRouteProps[] = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            ...HomeRoutes,
        ]
    },
]

export default Routes
