import React, { useCallback, useEffect } from 'react'
import { useMemo } from 'react'
import { useLocation } from "react-router-dom"
import { SnackbarList } from '../snackbar/SnackbarList'
import { RouteObject, useNavigate } from "react-router"
import { Routes } from './Routes'
import { useEnv } from '../../hooks/env'
import { useRedirectHard } from 'hooks/utils'
import { Redirection } from 'components/redirection/Redirection'

/**
 * Component that initialize user environment
 */
export const Shell = (props: ShellProps) => {

    const path = useMemo(() => window.location.pathname, [window.location.pathname])
    const env = useEnv()
    const location = useLocation()
    const navigate = useNavigate()
    const redirectHard = useRedirectHard()
    // const debounce = useDebounce()
    // const { setDocumentMetadata } = useMetadata()
    // const seo = useGetAllSEO()

    // const history = useHistory();
    // history.listen((_location) => {
    //     const route = props.routes.find((item) => item.path === _location.pathname) 
    //     route?.disableScrollTopOnChange !== true && window.scroll({ top: 0, behavior: 'smooth' })
    //     route?.path && checkAutorizedRoute(route?.path as string)
    //     checkMetadata(route?.path as string)

    //     if (_location.hash) {
    //         debounce(() => {
    //             const el = document.getElementById(_location.hash.replace('#', ''))
    //             el && window.scrollTo({ top: el?.offsetTop - 60, behavior: 'smooth' })
    //         }, 750)
            
    //     }
    // })

    useEffect(() => {
        // const route = props.routes.find((item) => item.path === location.pathname)
        // checkMetadata(route?.path as string)
    }, [])

    useEffect(() => {
        checkAutorizedRoute(path)
    }, [env, path])

    // const checkMetadata = (path?: string) => {
    //     const seoData = seo?.find((item) => item.url === path)
    //     setDocumentMetadata({
    //         path: seoData?.url || '',
    //         description: seoData?.metaDescription || process.env.REACT_APP_META_DESCRIPTION,
    //         title: seoData?.metaTitle || process.env.REACT_APP_META_TITLE,
    //         keywords: seoData?.metaKeywords || process.env.REACT_APP_META_KEYWORDS
    //     })
    // }

    const checkAutorizedRoute = (_path: string) => {
        return;
        if (env === null && !isRouteAuthorized(_path)) {
            if (_path !== props.loginPath) {
                redirectHard(`${props.loginPath}?redirectUrl=${location.pathname}`)
            }
            return;
        }

        if (!isRouteAuthorized(_path)) {
            navigate(props.initialPath || '/')
            return;
        }
    }

    const isRouteAuthorized = useCallback((_path: string) => {
        return true;
        if (
            props.loggedRoutes === '*' 
            && !props.loggedOutRoutes?.includes(_path)
            && env === null) {
            return false
        }

        if (props.loggedRoutes?.includes(_path) && env === null) {
            return false
        }

        if (env && props.loggedUnAuthorizedRootes?.some((item) => _path.includes(item))) {
            return false
        }

        return true
    }, [env])
    
    if (env === null && !isRouteAuthorized(path)) {
        return (<React.Fragment></React.Fragment>)
    }
    
    return (
        <React.Fragment>
            <Routes routes={props.routes} />
            <SnackbarList limit={3} />
            <Redirection />
        </React.Fragment>
    )
}

export type ShellProps = {
    routes: Array<EphattaRouteProps>
    /** 
     * Routes that authorized when user is logged 
     */
    loggedRoutes?: Array<string> | '*'
    /** 
     * Routes that authorized when user is logged out
     * It work if loggedRoutes is "*"
     */
    loggedOutRoutes?: Array<string>
    /** Routes that's are not allowed when user is logged */
    loggedUnAuthorizedRootes?: Array<string>
    /** Initial routes */
    initialPath?: string
    /** Path where redirect if current route is unAuthorized */
    loginPath: string
}

export type EphattaRouteProps = RouteObject & {
    children?: EphattaRouteProps[]
}
