import { Drawer, Grid, Hidden, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { MainLoader } from "components/progress";
import { Suspense } from "react";
import { Outlet } from "react-router";
import { MainMenu } from "./components/MainMenu";
import { MobileMenu } from "./components/MobileMenu";

export const MainLayout = () => {
    const classes = useStyles()

    return (
        
        <Grid container>
            <Grid item xs={12} sm={4} md={3} lg={2}>
                <Hidden smDown>
                    <Drawer style={{width: '100%'}}
                        variant="persistent" 
                        className={classes.drawerMenu}
                        open
                    >
                        <MainMenu></MainMenu>
                    </Drawer>
                </Hidden>
                <Hidden mdUp>
                    <MobileMenu/>
                </Hidden>
            </Grid>

            <Grid item xs={12} sm={8} md={9} lg={10}>
                <Suspense fallback={<MainLoader />}>
                    <Outlet />
                </Suspense>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    screen: {
        backgroundColor: theme.palette.secondary.light,
        '&.MuiGrid-root': {
            marginTop: 90,
        }
    },
    applicationFullScreen: {
        width: '100%',
        position: 'relative',
        minHeight: `${window.innerHeight}px`,
        background: '#f5f6fa',
        '& > div': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)'
        }
    },
    //#region main style
    application: {
        backgroundColor: '#f5f6fa',
        '& > div:nth-child(2)': {
            minHeight: `${window.innerHeight * 0.9}px`,
            width: '-webkit-fill-available'
        },
        '& > div:last-child': {
            minHeight: `${window.innerHeight * 0.1}px`,
            position: 'relative',
            backgroundColor: 'gray',
        },
    },
    closeMenuButton: {
        position: 'absolute',
        right: 0,
        margin: '20px 5px',
        padding: '10px',
        // backgroundColor: `${secondColor} !important`, CLEAN_ME
        zIndex: 1
    },
    drawerMenu: {
        '& .MuiDrawer-paper': {
            overflow: 'hidden',
            border: 'none',
            width: '17%'
        }
    }
}))
