import { List, ListItem, ListItemText, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import { useGetContractData } from "hooks/data/contract"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import InstagramIcon from '@mui/icons-material/Instagram';
import { useRecoilState } from "recoil"
import { SoldElyxAtom } from "states/SoldElyxStore"

export const MainMenu = () => {

    const classes = useStyles()
    const [sold, setSold] = useRecoilState(SoldElyxAtom);

    const { data } = useGetContractData()

    useEffect(() => {
        if(data) {
            setSold(1111 - (+data?.publicSaleTokenLeft! + +data?.greenlistTokenLeft! + +data.founderTokenLeft!))
        }
    }, [data])

    return (
        <List className={classes.mainList}>
            <ListItem>
                <img src="/assets/img/yacine-ak.gif" width="70%" />
            </ListItem>
            <ListItem>
                <img src="/assets/img/logo.png" />
            </ListItem>
            <ListItem>
                <ListItemText className={classes.mainListText}>
                    <span>1111 NFTS</span><br/>
                    <span className={classes.mainListTextBorder}>{data?.price} ETH</span>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText className={classes.mainListText}>
                    <span>SOLD</span><br/>
                    <span className={classes.mainListTextBorder}>{sold} / 1111</span>
                </ListItemText>
            </ListItem>
            {/* <ListItem>
                <ListItemText className={classes.mainListText}>
                    <span>DIGITALS LEFT</span><br/>
                    <span className={classes.mainListTextBorder}>{data?.digital}/111</span>
                </ListItemText>
            </ListItem>
            <ListItem>
                <ListItemText className={classes.mainListText}>
                    <span>PHYGITALS LEFT</span><br/>
                    <span className={classes.mainListTextBorder}>{data?.phygital}/111</span>
                </ListItemText>
            </ListItem> */}
            <Box sx={{ 
                bottom: 0,
                marginTop: 15
            }}>
                {/* 
                Twitter: https://twitter.com/ELYXyak_eth
                Discord: https://discord.gg/M4awPY4gcv
                OpenSea: https://opensea.io/collection/elyx-genesis-para11e1
                Instagram: https://www.instagram.com/elyxyak/?hl=fr
                 */}
                <ListItem>
                    <a target="_blank'" href='https://twitter.com/ELYXyak_eth'>
                        <img src="/assets/img/social_media/Vector_3.png"/>
                    </a>
                </ListItem>
                <ListItem>
                    <a href='https://opensea.io/collection/elyx-genesis-para11e1' target="_blank">
                        <img src="/assets/img/social_media/Vector_2.png"/>
                    </a>
                </ListItem>
                <ListItem>
                    <a href='https://discord.gg/M4awPY4gcv' target="_blank">
                        <img src="/assets/img/social_media/Vector_1.png"/>
                    </a>
                </ListItem>
                <ListItem>
                    <a href='https://www.instagram.com/elyxyak/?hl=fr' target="_blank">
                        <InstagramIcon color="primary" />
                    </a>
                </ListItem>
            </Box>
        </List>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    mainList: {
        '& .MuiListItem-root': {
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center'
        },
    },
    mainListText: {
        '& .MuiTypography-root': {
            fontSize: 18,
            fontWeight: 'bold',
            color: theme.palette.primary.main
        }
    },
    mainListTextBorder: {
        WebkitTextStroke: `1px ${theme.palette.primary.main}`,
        fontWeight: 900,
        fontSize: 24,
        color: '#fff'
    }
}))



