import { ThemeOptions } from "@mui/material";

const primary = {
    main: '#000000',
    light: '#ffffff'
}

const secondary = {
    main: '#F2C814',
    light: '#f4f4f4'
}

const info = {
    main: '#2B90DE',
    light: '#ffffff'
}

const text = {
    primary: '#0b0e0f',
    secondary: '#333333',
}

export const Theme: ThemeOptions = {
    palette: {
        primary,
        secondary,
        info,
        text
    },
    components: {
        
        MuiTextField: {
            styleOverrides: {
                root: {
                    
                },
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderWidth: 2,
                    borderRadius: 8
                }
            }
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: primary.main
                }
            }
        }
    },
}
