import { makeStyles } from "@mui/styles";

export const usePitctureUploaderStyle = makeStyles((theme) => ({
    cropContainer: {
        margin: 'auto',
        position: 'relative',
        width: '80%',
        height: 300,
        background: '#333',
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
    },
    controls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    slider: {
        padding: '22px 0px',
        marginLeft: 16,
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 16px',
        width: '70%',
        color: theme.palette.primary.main
    },
    options: {
        display: 'flex'
    },
    buttonRotation: {
        minWidth: '45px',
        height: '45px',
        padding: 0,
        margin: '10px',
        backgroundColor: theme.palette.primary.main,
        border: '1px solid white',
        borderRadius: '10px',
        boxShadow: 'none',
        '& svg': {
            color: 'white',
            fontSize: '20px',
        },
        '&:hover': {
            border: '1px solid theme.palette.primary.main',
            backgroundColor: 'white',
            '& svg': {
                color: theme.palette.primary.main
            }
        }
    },
    saveButton: {
        backgroundColor: theme.palette.primary.main,
        border: '1px solid white',
        borderRadius: '10px',
        boxShadow: 'none',
        '& svg': {
            color: 'white',
            fontSize: '20px',
        },
        '&:hover': {
            border: '1px solid theme.palette.primary.main',
            backgroundColor: 'white',
            color: theme.palette.primary.main
        }
    },
    inputPicture: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        padding: '20px 15px',
        borderRadius: '20px',
        border: `1px solid ${theme.palette.common.white}`,
        fontSize: '15px',
        textDecoration: 'none',
        textShadow: 'none',
        display: 'inline-block',
        cursor: 'pointer',
        margin: '15px auto',
        width: '100%',
        position: 'relative',
        textAlign: 'center',
        '&:hover': {
            color: theme.palette.primary.main,
            backgroundColor: `${theme.palette.common.white} !important`,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& #upload': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            opacity: 0,
            cursor: 'pointer',
        },
    },
}))