import { AppConfig, TLoginRequest, TIdentity } from "../models";
import { HttpService, Logger, setAccessToken } from "../utils";
import { BaseService } from "./BaseService";

export class LoginService extends BaseService {

    constructor(params: AppConfig){
        super(params)
    }

    async login(payload: TLoginRequest) {
        try {
            const { data } = await HttpService.post(
                `${this.controllerUrl}/identity/login`, 
                payload
            );
            setAccessToken(data.accessToken)
            return data;
        }
        catch (err) {
            Logger.error(err, { method: this.login.name });
            throw err;
        }
    }

    async register(payload: any) {
        try {
            const {  data } = await HttpService.post(
                `${this.controllerUrl}/identity/register`, 
                payload
            );
            setAccessToken(data.accessToken)
            return data;
        }
        catch (err) {
            Logger.error(err, { method: this.register.name });
            throw err;
        }
    }

    async identity(): Promise<TIdentity | null> {
        try {
            const result = await HttpService.get(
                `${this.controllerUrl}/identity`,
                {}
            );
            return result.data?.getProfilResult.value;
        }
        catch (err) {
            Logger.error(err, { method: this.identity.name });
            throw err;
        }
    }
}
