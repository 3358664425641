import _ from 'lodash';
import { useEphattaQuery } from 'hooks/utils';
import { useContactService } from '.';

export const useGetContractData = () => {
    const service = useContactService()
    
    return useEphattaQuery(
        ['Account.Contract.Data'], 
        () => service.getContractData()
    )
}