import { useCallback } from "react";
import { QueryFunction, QueryKey, useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from "react-query";
import { useMonitorQuery, UseMonitorQueryConfig } from "./useMonitorQuery";

export const useEphattaQuery = <TData=Record<string, any>, TError = unknown>(
    queryKey: QueryKey,
    fn?: QueryFunction<TData>,
    config?: UseEphattaQueryOptions<unknown, TData, TError>) : UseEphattaQuery<TData, TError>  =>  {

    const queryInstance = useQuery(queryKey, fn!, { enabled: fn !== undefined, ...config });
    const queryClient = useQueryClient()
    const invalidateQuery = useCallback(() => {
        queryClient.invalidateQueries(queryKey)
    }, [queryKey])

    const removeQuery = useCallback(() => {
        queryClient.removeQueries(queryKey)
    }, [queryKey])

    const resetQuery = useCallback(() => {
        queryClient.resetQueries(queryKey)
    }, [queryKey])

    useMonitorQuery<TData, TError>(queryInstance, {
        errorMessage: !config?.hideErrorMessage ? 'Une erreur s\'est produite' : undefined,
        onErrorActionClick: () => queryInstance.refetch(),
        transformErrorMessage: (e?: any) => {
            if (e?.response?.status === 0) {
                return 'Serveur injoignable'
            }
            return 'Une erreur s\'est produite'
        },
        ...config,
    }, queryKey)

    return {
        ...queryInstance,
        invalidateQuery,
        removeQuery,
        resetQuery,
    }
};

export type UseEphattaQuery<TData, TError> = UseQueryResult<TData, TError> & {
    invalidateQuery: () => void
    removeQuery: () => void
    resetQuery: () => void
}

export type UseEphattaQueryOptions<TQueryFnData = unknown, TData = unknown, TError = unknown> = 
    UseQueryOptions<TQueryFnData, TError, TData>
    & UseMonitorQueryConfig<TData, TError>