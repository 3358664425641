import { AppBar, Grid, List, ListItem, ListItemText, Theme } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { Box } from "@mui/system"
import { useGetContractData } from "hooks/data/contract"
import { Link } from "react-router-dom"
import InstagramIcon from '@mui/icons-material/Instagram';

export const MobileMenu = () => {

    const classes = useStyles()

    const { data } = useGetContractData()

    return (
        <>
            <AppBar position="fixed" className={classes.menuContainer} sx={{ 
                top: 'auto', 
                bottom: 0, 
                textAlign: 'center',
                background: 'white'
            }}>
                <Grid container justifyContent="center" className="content-center">
                    <Grid item xs={12}>
                        <img src="/assets/img/logo_mobile.png" style={{ width: 150 }}/>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className={classes.mainListText}>
                        <span>1111 NFTS</span><br/>
                        <span className={classes.mainListTextBorder}>{data?.price} ETH</span>
                    {/* <span className={classes.mainListTextBorder}>Free MINT</span> */}
                        </Box>
                    </Grid>
                    {/* <Grid item xs={6}>
                        <Box className={classes.mainListText}>
                        <span>PHYGITALS LEFT</span><br/>
                    <span className={classes.mainListTextBorder}>{data?.phygital}/111</span>
                        </Box>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" my={1}>
                            <Box>
                                {/* 
                                Twitter: https://twitter.com/ELYXyak_eth
                                Discord: https://discord.gg/M4awPY4gcv
                                OpenSea: https://opensea.io/collection/elyx-genesis-para11e1
                                Instagram: https://www.instagram.com/elyxyak/?hl=fr
                                */}
                                <a target="_blank'" href='https://twitter.com/ELYXyak_eth'>
                                    <img src="/assets/img/social_media/Vector_3.png"/>
                                </a>
                            </Box>
                            <Box ml={1}>
                                <a href='https://opensea.io/collection/elyx-genesis-para11e1' target="_blank">
                                    <img src="/assets/img/social_media/Vector_2.png"/>
                                </a>
                            </Box>
                            <Box ml={1}>
                                <a href='https://discord.gg/M4awPY4gcv' target="_blank">
                                    <img src="/assets/img/social_media/Vector_1.png"/>
                                </a>
                            </Box>
                            <Box ml={1}>
                                <a href='https://www.instagram.com/elyxyak/?hl=fr' target="_blank">
                                    <InstagramIcon color="primary" />
                                </a>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </AppBar>
        </>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
    menuContainer: {
        background: theme.palette.common.white
    },
    mainListText: {
        top: 0,
        fontSize: 18,
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    mainListTextBorder: {
        WebkitTextStroke: `1px ${theme.palette.primary.main}`,
        fontWeight: 900,
        fontSize: 24,
        color: '#fff'
    }
}))



