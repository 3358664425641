import ToolKit from './Toolkit';

const log = (level: LogLevel, msg: any | any[], options?: LogOptions): void => {
    if (!Object.values(LogLevel).includes(level)) {
        return;
    }
    let consoleFn: Function;

    switch (level) {
        case LogLevel.Info:
            consoleFn = console.log; // eslint-disable-line no-console
            break;
        case LogLevel.Warning:
            consoleFn = console.warn; // eslint-disable-line no-console
            break;
        case LogLevel.Error:
            consoleFn = console.error; // eslint-disable-line no-console
            break;
        default:
            return;
    }

    if (!ToolKit.isFunction(consoleFn)) return;

    if (ToolKit.isUndefinedOrNull(options) || (ToolKit.isUndefinedOrNull(options.method) &&
        ToolKit.isUndefinedOrNull(options.context))) {
        consoleFn(...msg);
    }
};

class Logger {

    internalConfig: LogConfig

    constructor(){
        this.internalConfig = {}
    }

    setConfig(config: LogConfig) {
        this.internalConfig = config;
    }

    info(msg: any | any[], options?: LogOptions): void {
        log(LogLevel.Info, msg, options);
    }

    warn(msg: any | any[], options?: LogOptions): void {
        log(LogLevel.Warning, msg, options);
    }

    error(msg: any | any[], options?: LogOptions): void {
        log(LogLevel.Error, msg, options);
    }
}

export type LogConfig = {
    level?: {
        debug?: boolean,
        warning?: boolean,
        error?: boolean,
        state?: boolean
    }
}

interface LogOptions {
    tags?: string[];
    context?: any;
    method?: Function | string;
}

export enum LogLevel {
    Info,
    Warning,
    Error
}

export default new Logger();
