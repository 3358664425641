import React from "react"
import { Grid, LinearProgress } from "@mui/material"
import { makeStyles } from "@mui/styles"

export const MainLoader = () => {
    const classes = useStyle()
    return (
        <Grid className={classes.mainLoader} container justifyContent="center" alignItems="center" >
            <Grid item>
                <img src="/assets/img/logo.png" />
                <LinearProgress />
            </Grid>
        </Grid>
    )
}

const useStyle = makeStyles((theme) => ({
    mainLoader: {
        position: 'absolute',
        inset: 0,
        '& img': {
            width: 240,
            marginBottom: 10
        }
    }
}))
