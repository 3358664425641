import { atom } from "recoil";

export const RedirectionState = atom<RedirectionType | null>({
    key: 'state.RedirectionState',
    default: null
});

export type RedirectionType = {
    url: string
    isTargetBlank?: boolean
    data: Record<string, any>
}
