import ToolKit from './Toolkit';

interface IHttpOption {
    token?: string;
    headers?: KeyValue<string, string>[];
}

interface IRequestOption {
    params?: Record<string, any>;
    data?: any;
    token?: string;
    headers?: KeyValue<string, string>[];
}

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

const tokenKey = 'token'

const isJsonContentType = (response: Response): boolean => {
    const contentType = response.headers.get('content-type')
    if (!contentType) {
        return false
    }

    return !!(contentType &&
    (contentType!.toLowerCase().indexOf('application/json') >= 0)
    || contentType!.toLowerCase().indexOf('application/problem+json') >= 0)
}

const generateHeaders: (
    defaultHeaders: Record<string, string>,
    options: IHttpOption
) => Promise<Record<string, string>> = async (defaultHeaders, options) => {
    const headers = defaultHeaders;

    if (ToolKit.isArray(options.headers)) {
        options.headers.forEach(header => {
            if (ToolKit.isUndefinedOrNull(header) ||
                !ToolKit.isString(header.key)) {
                throw new Error('Invalid header format');
            }

            if (!ToolKit.isEmpty(header.value)) delete headers[header.key];
            headers[header.key] = header.value;
            if (ToolKit.isEmpty(header.value)) delete headers[header.key];
            // TODO: Check if good behavior
        });
    }

    return headers;
};

const isContentTypeOverrided = (headers?: KeyValue<string, string>[]): boolean =>
    ToolKit.isArray(headers) && headers.map(header => header.key.toLowerCase()).indexOf('content-type') >= 0;

const sendRequest: <TData = any>(
    url: string,
    method: HttpMethod,
    options: IRequestOption
) => Promise<HttpResponse<TData>> = async (url, method, options) => {
    if (!ToolKit.isString(url)) throw new TypeError('input url is not valid.');
    if (!['GET', 'POST', 'PUT', 'DELETE'].includes(method)) throw new TypeError('input method is not valid.');
    if (ToolKit.isUndefinedOrNull(options)) throw new TypeError('input options are not valid.');

    const accessToken = getAccessToken();
    const response = new HttpResponse();
    const headers = await generateHeaders({ 
        'Content-Type': 'application/json', 
        'Authorization': accessToken ? `Bearer ${accessToken}` : ''
    }, options);
    const initData: RequestInit = { method: method.toLowerCase(), headers };
    const contentTypeOverrided = isContentTypeOverrided(options.headers);

    let fetchResponse: Response;
    switch (method) {
        case 'GET':
        case 'DELETE':
            const urlObject: any = new URL(url);
            if (ToolKit.isObject(options.params)) {
                const { params } = options;
                Object.keys(params).forEach(key => urlObject.searchParams.append(key, params[key]));
            }

            fetchResponse = await fetch(urlObject, initData);
            break;
        case 'POST':
        case 'PUT':
            initData.body = contentTypeOverrided ? options.data : JSON.stringify(options.data);
            fetchResponse = await fetch(url, initData);
            break;
        default:
            throw new Error('Unhandled http method.');
    }

    response.status = fetchResponse.status;
    const strStatus = fetchResponse.status.toString();
    if (isJsonContentType(fetchResponse)) response.data = await fetchResponse.json();
    else response.data = fetchResponse;

    if (strStatus.startsWith('4') || strStatus.startsWith('5')) throw response; // HTTP Codes 4XX and 5XX trigger are categorized as error

    return response;
};

export const setAccessToken = (accessToken: string) => {
    localStorage.setItem(tokenKey, accessToken)
}

export const getAccessToken = () => {
    return localStorage.getItem(tokenKey)
}

export class HttpResponse<T = any> {
    status: number = -1;
    data: T | null = null;
    error: string | null = null;
}

export class HttpService {
    static get: <T = any>(
        url: string,
        params: Record<string, any>,
        options?: IHttpOption
    ) => Promise<HttpResponse<T>> = async (url, params, options) => (
        sendRequest(url, 'GET', {
            params,
            token: !ToolKit.isUndefinedOrNull(options) ? options.token : undefined,
            headers: !ToolKit.isUndefinedOrNull(options) ? options.headers : undefined
        }));

    static post: <T = any>(
        url: string,
        data: any,
        options?: IHttpOption
    ) => Promise<HttpResponse<T>> = async (
        url,
        data,
        options
    ) => sendRequest(url, 'POST', {
        data,
        token: !ToolKit.isUndefinedOrNull(options) ? options.token : undefined,
        headers: !ToolKit.isUndefinedOrNull(options) ? options.headers : undefined
    });

    static put: <T = any>(
        url: string,
        data: any,
        options?: IHttpOption
    ) => Promise<HttpResponse<T>> = async (url, data, options) => (
        sendRequest(url, 'PUT', {
            data,
            token: !ToolKit.isUndefinedOrNull(options) ? options.token : undefined,
            headers: !ToolKit.isUndefinedOrNull(options) ? options.headers : undefined
        }));

    static delete: <T = any>(
        url: string,
        params: Record<string, any>,
        options?: IHttpOption
    ) => Promise<HttpResponse<T>> = async (url, params, options) => (
        sendRequest(url, 'DELETE', {
            params,
            token: !ToolKit.isUndefinedOrNull(options) ? options.token : undefined,
            headers: !ToolKit.isUndefinedOrNull(options) ? options.headers : undefined
        }));
}

/**
 * Check GET query and return it as object of key value
 * @protected
 * @param {String} uri 
 * @returns {Object}
 */
export const getUriQuery = (uri: string): Record<string, any> => {
    const search_params = new URLSearchParams(uri.replace(/.*\?(.*)/g, '$1'))
    const result: Record<string, any> = {}
    search_params.forEach((value: string, key: string, parent: URLSearchParams) => {
        result[key.replace(/\[\]$/, '')] = /\[\]$/.test(key) 
            ? parent.getAll(key).map((item) => (isValidJson(item) ? JSON.parse(item) : item))
            : (isValidJson(value) ? JSON.parse(value) : value)
    })
    return result
}

/**
* Transform object to uri GET query
* @param {object} data 
*/
export const getUrlEncoded = (data?: Record<string, any>) : string => {
   let str = ''

   for(var prop in data){
       if(Array.isArray(data[prop])){
            const adr = data[prop] as Array<string>
            adr.forEach((item: string) => {
                const val = typeof item === 'string' ? item : JSON.stringify(item)
                str += `${prop}[]=${val}&`
            })
       }
       else if (data[prop]) {
           const val = typeof data[prop] === 'string' ? data[prop] : JSON.stringify(data[prop])
           str += `${prop}=${val}&`
       }
   }

   return str.substring(0, str.length - 1)
}

/**
 * Check if string is a valid json
 * @param str 
 * @returns result
 */
export const isValidJson = (str: string): boolean => {
    try{
        JSON.parse(str)
        return true
    }catch(e){
        return false
    }
}
