import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-xhr-backend';


i18n.use(initReactI18next)
.use(Backend)
// .use(LanguageDetector)
.init({
    keySeparator: false,
    fallbackLng: 'fr',
    debug: true,
    initImmediate: false,
    backend: {
        loadPath: '/i18n/{{lng}}/{{ns}}.json',
    }
})

export default i18n