import { config } from "config/Config";
import {  atom, selector } from "recoil";
import { TIdentity } from "services/models";
import { LoginService } from "services/services/LoginService";

const loginService = new LoginService(config)

export const Environment = selector<TIdentity | null>({
    key: 'User.Environment',
    get: async () => {

        return null
        try {
            const env = await loginService.identity()
            return env
        } catch (e) {
            console.log('error', e)
            return null
        }
    }
})
